<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
      <v-col cols="12" :sm="step === 2 ? '11' : '12'">
        <base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
      </v-col>
			<v-col cols="12" sm="1" align="center">
        <base-btn v-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
    </v-row>

		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">

						<v-form ref="form">
							<v-row>
								<v-col cols="12" sm="4" md="4" lg="4" xl="4" >
									<base-select v-model="editedSearchItem.branchOfficeId" :items="branchOffices" label="Sucursal" 
									:rules="[commonValRules.required]" required></base-select>
								</v-col>
								<v-col cols="12" sm="5" md="5" lg="4" xl="4">
									<base-date-range-picker-dialog @range="dateRange" :rules="[commonValRules.required]" required/>
								</v-col>
								<v-col cols="12" sm="2" md="2" lg="2" xl="2" >
									<base-btn dark color="teal" class="mt-2" @click="toList()" icon="mdi-table-search" btnText="Buscar" :loading="loadingSearchForm"></base-btn>
								</v-col>
							</v-row>
						</v-form>

						<v-row>
							<v-col cols="12">
								<base-data-table 
									:loading="loadingSearchForm"
									loading-text="Cargando datos..."
									v-model="sortBy"
									desc
									:headers="customHeaders" 
									:items="sales" 
									hideNewItemButton
									@loadDataTable="toList"
								>
									<template v-slot:item.options="{ item }">
										<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
										<base-icon-btn v-if="item.statusId!==$Const.STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>
									</template>

									<template v-slot:item.status="{ item }">
										<template v-if="item.statusId===$Const.STATUS.REGISTERED">
											<span class="blue--text">{{item.status}}</span>
										</template>
										<template v-else-if="item.statusId===$Const.STATUS.APPROVED">
											<span class="success--text">{{item.status}}</span>
										</template>
										<template v-else>
											<span class="red--text">{{item.status}}</span>
										</template>
									</template>

									<template v-slot:item.paymentType="{ item }">
										<base-icon v-if="item.paymentType == 1" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'green darken-1'" tooltip="Efectivo">mdi-cash</base-icon>
										<base-icon v-else-if="item.paymentType === 2" :color="item.statusId===$Const.STATUS.CANCELLED ? 'red' : 'info darken-1'" tooltip="Tajeta">mdi-credit-card-outline</base-icon>
										<template v-else-if="item.paymentType === 3">
											<base-icon v-if="item.creditStatusId===$Const.STATUS.CREDIT_PENDING" color="secondary" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
											<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CREDIT_COMPLETED" color="green darken-1" :tooltip="item.creditStatus">mdi-account-cash</base-icon>
											<base-icon v-else-if="item.creditStatusId===$Const.STATUS.CANCELLED" color="red" :tooltip="`Crédito ${item.creditStatus}`">mdi-account-cash</base-icon>
										</template>
									</template>

									<template v-slot:item.dateTime="{ item }">
										<base-form-date-list :dateTimeToShow="item.dateTime" 
											:showDialog="item.showDateTimeDialog"
											:formTitle="`Información venta: ${item.id}`"
											:headers="dateTimeHeaders"
											:items="item.dateTimeItems"
											@open="openDateTimeDialog(item)"
											@close="closeDateTimeDialog(item)"
										></base-form-date-list>
									</template>
								</base-data-table>
							</v-col>
						</v-row>
					</v-window-item>
					<v-window-item :value="2">
						<base-form-simple ref="SaleForm"
							formTitle="Venta"
							hideSaveButton
							@cancelButton="close"
						>
							<template v-slot:formContent>
									<v-row justify="center">
										<v-col v-if="showReason" cols="12">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<v-icon color="red" left>mdi-alert-circle</v-icon>
												<strong>La venta fue anulada por el siguiente motivo: </strong> {{editedItem.reason}}
											</v-alert>
										</v-col>

										<!-- Datos de la venta -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos de la venta"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="editedItem.id" label="Nro. de Venta" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="editedItem.status" label="Estado" readonly>
															</base-text-field>
														</v-col>

														<v-col cols="12" sm="6" md="4">
															<base-text-field v-model="voucherType" label="Comprobante" readonly>
															</base-text-field>
														</v-col>

														<template v-if="showInvoiceData">
															<v-col cols="12" sm="6" md="4">
																<base-text-field v-model="editedItem.invoiceNumber" label="Factura N°" readonly>
																</base-text-field>
															</v-col>
															<v-col cols="12" sm="6" md="4">
																<base-text-field v-model="editedItem.authorizationNumber" label="Autorización N°" readonly>
																</base-text-field>
															</v-col>
														</template>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos del cliente -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos del cliente"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="9" sm="8" md="7">
															<base-text-field v-model="editedItem.customer" label="Cliente" readonly>
															</base-text-field>
														</v-col>
														<v-col cols="3" sm="4" md="5" align="center">
															<base-number-field v-model="editedItem.generalDiscount" label="Descuento" suffix="%" readonly>
															</base-number-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Detalles de los productos -->
										<v-col cols="12">
											<base-form-container
												title="Productos"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" align="center">
															<base-data-table ref="DtDetails"
																:headers="customDetailHeaders" 
																:items="details"
																sortBy="customCode"
																hideHeader
																:show-expand="showExpand"
																item-key="productId"
																elevation="0"
																showBorder
															>
																<template v-slot:expanded-item="{ item }">
																	<base-form-stock-details :ref="`StockForm${item.productId}`" :stockFormId="item.stockFormId" :title="`Detalles: ${item.product}`" :details="item.stockDetails" mode="ShowData">
																	</base-form-stock-details>
																</template>

																<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																	<v-expand-transition v-if="item.stockFormId !== $Const.STOCK_FORM.GENERAL">
																		<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(!isExpanded)" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																		<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																	</v-expand-transition>
																</template>

																<template v-slot:item.image="{ item }">
																	<div style="width:100px;">
																		<base-product-img v-model="item.image" :productId="item.productId"
																			class="my-2"
																			gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																			aspect-ratio="1.5"
																			contain
																		>
																		</base-product-img>
																	</div>
																</template>

																<template v-slot:item.quantity="{ item }">
																	<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																</template>

																<template v-slot:item.unitPrice="{ item }">
																	Bs. {{ item.unitPrice }}
																</template>

																<template v-slot:item.subtotalPrice="{ item }">
																	Bs. {{ item.subtotalPrice }}
																</template>

																<template v-slot:item.discount="{ item }">
																	Bs. {{ item.discount }}
																</template>

																<template v-slot:item.totalPrice="{ item }">
																	Bs. {{ item.totalPrice }}
																</template>

																<template v-slot:no-data>
																	<h3 class="red--text">*No hay productos agregados al detalle.</h3>
																</template>
															</base-data-table>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos finales -->
										<v-col cols="12" sm="6" order-sm="2" align="right">
											<base-form-container
												title="Datos finales"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:titleContent>
													<v-spacer></v-spacer>
													<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
														<v-icon dark>mdi-cart-variant</v-icon>
													</v-badge>
												</template>

												<template v-slot:content>
													<v-row align="center" justify="center" no-gutters>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Productos en Total</strong>
																<p class="mt-2 mb-0">{{calculateTotalProducts}}</p>
															</h3>
														</v-col>
														<v-col cols="6" sm="6" align="center">
															<h3>
																<strong>Sub Total</strong>
																<p class="mt-2 mb-0">Bs. 
																	{{editedItem.subtotalPrice}}
																</p>
															</h3>
														</v-col>
														<v-col cols="6" sm="6" align="center" class="pt-4">
															<h3>
																<strong>Descuento ({{editedItem.generalDiscount}}%)</strong>
																<p class="mt-2 mb-0">Bs. 
																	{{editedItem.totalDiscount = calculateTotalDiscount}}
																</p>
															</h3>
														</v-col>
														<v-col cols="6" sm="6" align="center" class="pt-4">
															<h3 class="mt-6 mt-sm-0">
																<strong>Total a Pagar</strong>
																<p class="mt-2 mb-0">Bs. 
																	{{editedItem.totalPrice}}
																</p>
															</h3>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Formulario de pagos -->
										<v-col cols="12" sm="6" order-sm="1">
											<base-form-payment-type ref="PaymentForm" v-model="paymentData" :totalPrice="editedItem.totalPrice" disabledFields :status="editedItem.creditStatusId">
											</base-form-payment-type>
										</v-col>

										<!-- Detalle de Facturación -->
										<v-col v-if="issueInvoices" cols="12" sm="6" order-sm="3">
											<base-form-container
												title="Facturación"
												titleClasses="secondary accent-4 white--text"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col cols="12" sm="5" md="5">
															<base-number-field fullWidth v-model="editedItem.nit" label="NIT/CI" readonly>
															</base-number-field>
														</v-col>
														<v-col cols="12" sm="7" md="7">
															<base-text-field class="uppercase-input" v-model="editedItem.businessName" label="Razon Social" readonly>
															</base-text-field>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>
									</v-row>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'
export default {
	mixins: [MainExternalData],

	data() {
		return {
			title: 'Reporte de Ventas',
			step: 1,
			sortBy: 'dateTime',
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Venta", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Cliente", value: "customer" },
				{ text: "Tipo Comprobante", value: "voucherType" },
				{ text: "Nro. Factura", value: "invoiceNumber", sortable: false},
				{ text: "Nro. Autorización", value: "authorizationNumber", sortable: false},
				{ text: "Fecha", value: "dateTime" },
				{ text: "Total Bs.", value: "totalPrice", sortable: false},
				{ text: "Tipo Pago", value: "paymentType" },
        { text: "Estado", value: "status"}
			],
			sales: [],
			detailHeaders: [
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: "Precio Unit", value: "unitPrice", sortable: false },
				{ text: "Subtotal", value: "subtotalPrice", sortable: false },
				{ text: "Descuento", value: "discount", sortable: false },
				{ text: "Total", value: "totalPrice", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand', sortable: false },
			],
			details: [],
			showExpand: false,
			branchOffices:[],
			editedSearchItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
			},
			selectedItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
			},
			defaultSearchItem: {
				branchOfficeId: '',
				startDate:'',
				endDate:'',
			},
			loadingSearchForm:false,
			editedItem: {
				id: '',
				customer:'',
				voucherTypeId:'',
				invoiceNumber:'',
				authorizationNumber:'',
				nit:'',
				businessName:'',
				subtotalPrice: 0,
				generalDiscount:0,
				totalDiscount: 0,
				totalPrice: 0,
				dateTime:'',
				statusId: 0,
				status: '',
				creditStatusId: 0
      },
      defaultItem: {
       	id: '',
				customer:'',
				voucherTypeId:'',
				invoiceNumber:'',
				authorizationNumber:'',
				nit:'',
				businessName:'',
				subtotalPrice: 0,
				generalDiscount:0,
				totalDiscount: 0,
				totalPrice: 0,
				dateTime:'',
				statusId: 0,
				status: '',
				creditStatusId: 0
			},
			//PAYMENT DATA
			paymentData: {
				paymentTypeId: 1,
				amountPaid: '',
				cardNumber:'',
				nextPaymentDate:'',
				creditPaymentTypeId: 1
			},

			//SALE DATE TIMES
			dateTimeHeaders: [
				{ text: "Nro", value: "row", sortable: false },
				{ text: "Usuario", value: "userFullName", sortable: false },
				{ text: "Fecha y hora", value: "dateTime", sortable: false },
        { text: "Estado", value: "status", sortable: false },
			],

			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				saleId:'',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				saleId:'',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		showMainReport(){
			if (this.step === 2 || this.sales.length<=0) {
				return false;	
			}
			return true;
		},

		issueInvoices(){
			return this.editedItem.voucherTypeId === 1;
		},

		voucherType(){
			return this.editedItem.voucherTypeId === 1 ? 'Factura' : 'Recibo';
		},

		showInvoiceData(){
			if (this.issueInvoices) {
				return true;
			}
			return false;
		},

		customHeaders(){
			if (this.companyData.enableInvoice) {
				return this.headers;
			}

			let result = this.headers.filter(x => x.value != 'invoiceNumber' && x.value != 'authorizationNumber');
			return result;
		},

		customDetailHeaders(){
			var excludes = [];

			this.showExpand = true;
			if (!this.details.some(x => x.stockFormId != this.$Const.STOCK_FORM.GENERAL)) {
				this.showExpand = false;
				excludes.push('data-table-expand');
			}

			if (excludes.length <= 0) {
				return this.detailHeaders;
			}
			return this.detailHeaders.filter(x => !excludes.includes(x.value));
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},

		calculateTotalDiscount(){
			var result=0.0;
			if (this.editedItem.generalDiscount > 0) {
				result = (this.editedItem.subtotalPrice * (this.editedItem.generalDiscount / 100));
			}
			return parseFloat(result).toFixed(2);
		},

		showReason(){
			var result = this.step===2 && this.editedItem.statusId===this.$Const.STATUS.CANCELLED;
			return result;
		}
	},

	created() {
		this.listOfBranchOffices();
  },

	methods: {
		dateRange(dates = []){
			if (dates == null) {
				this.editedSearchItem.startDate = this.defaultSearchItem.startDate;
				this.editedSearchItem.endDate = this.defaultSearchItem.endDate;
			}
			else {
				this.editedSearchItem.startDate = dates[0];
				this.editedSearchItem.endDate = dates[1];
			}
		},

		async toList(){
			if (!this.$refs.form.validate()) {
        return;
			}
			let me=this;
			try {
				me.SHOW_LOADING()
				me.sales=[];
				me.loadingSearchForm=true;
				me.selectedItem = Object.assign({}, me.defaultSearchItem);
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedSearchItem.branchOfficeId,
					'startDate': me.editedSearchItem.startDate,
					'endDate': me.editedSearchItem.endDate
				};
				await me.getObjectResponse('api/CompanySale/GetReportByFilter', request).then(data => {
					if (data !== undefined) {
						me.sales = me.addCustomProperties(data.sales);
						me.selectedItem = Object.assign({}, me.editedSearchItem);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingSearchForm=false;
      }
		},

		addCustomProperties(originalSales) {
			let sales = originalSales;
			sales.map(x => {
				x.showDateTimeDialog = false;
				x.dateTimeItems = [];
			});
			return sales;
		},

		async listOfBranchOffices(){
			let me=this;
			try {
        me.SHOW_LOADING()
				var branchOfficesArray=[];
				await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
					if (data !== undefined) {
						branchOfficesArray = data.branchOffices; 
						branchOfficesArray.map(function(x){
							me.branchOffices.push({text:x.visualName, value:x.id});
							if (x.main) {
								me.editedSearchItem.branchOfficeId = x.id;
							}
						});
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.selectedItem.branchOfficeId,
					'id': item.id,
				};
				await me.getObjectResponse('api/CompanySale/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.editedItem = Object.assign({}, data.sale);
					me.details = me.setDetails(data.sale.details);
					me.paymentData = Object.assign({}, 
					{
						paymentTypeId: data.sale.paymentTypeId,
						amountPaid: data.sale.amountPaid,
						cardNumber: data.sale.cardNumber,
						nextPaymentDate: data.sale.nextPaymentDate
					});
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var stockDetails = this.parseStockDetails(element.stockDetails, stockFormId)
				result.push({
					productId: element.productId,
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 
					unitPrice: parseFloat(element.unitPrice).toFixed(2),
					subtotalPrice: parseFloat(element.subtotalPrice).toFixed(2),
					discount: parseFloat(element.discount).toFixed(2),
					totalPrice: parseFloat(element.totalPrice).toFixed(2),

					stockFormId: stockFormId,
					stockDetails: stockDetails
				});
			}
			return result;
		},

		parseStockDetails(stockDetails, stockFormId) {
			if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
				return [];
			}
			var result = JSON.parse(stockDetails)
			return result;
		},

    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			this.$refs.DtDetails.collapseAll();
			this.$refs.SaleForm.reset();
			this.$refs.PaymentForm.reset();
			this.editedItem = Object.assign({}, this.defaultItem);
			this.details = [];
		},

		async openDateTimeDialog(item){
			let me=this;
			try {
        me.SHOW_LOADING()
				item.dateTimeItems=[];
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'id': item.id,
				};

				if(item.statusId==me.$Const.STATUS.CANCELLED)
				{
					me.dateTimeHeaders.push({ text: "Motivo", value: "reason", sortable: false });
				}

				await me.getObjectResponse('api/CompanySale/GetDatesById', request).then(data => {
					if (data !== undefined) {
						item.dateTimeItems = data.dateTimeList;
						item.showDateTimeDialog=true;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    closeDateTimeDialog(item) {
			item.showDateTimeDialog=false;
			item.dateTimeItems=[];
			if(item.statusId==this.$Const.STATUS.CANCELLED)
			{
				const index = this.dateTimeHeaders.indexOf(x => x.value == "reason");
				this.dateTimeHeaders.splice(index, 1);
			}
    },
		
		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.selectedItem.branchOfficeId,
					'startDate': me.selectedItem.startDate,
					'endDate': me.selectedItem.endDate
				};
				await me.getObjectResponse('api/CompanySale/GetFilteredReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = me.title;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.selectedItem.branchOfficeId,
					'id': item.id,
					'getCopy': true
				};
				await me.getObjectResponse('api/CompanySale/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Venta ${me.reportFile.saleId}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>